import styled from 'styled-components';

export const Container = styled.div`
  .titles {
    img {
      width: 100px;
      height: 100px;
    }
    span {
      color: #000;
      font-family: 'Source Sans Pro';
      font-size: 22px;
      font-weight: 400;
      letter-spacing: 0.56px;
    }

    h1 {
      color: #000;
      font-family: 'Playfair Display';
      font-size: 40px;
      font-weight: 500;
      line-height: 117.078%;
      letter-spacing: 2.8px;
    }

    h2 {
      color: #000;
      font-family: 'Source Sans Pro';
      font-size: 22px;
      font-weight: 400;
    }
  }

  p {
    color: #000;

    text-align: center;
    font-family: 'Anaheim';
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
    span {
      color: #ffa33b;

      font-weight: 500;
    }
  }

  form {
    background: #fafafa;
    border: 1px solid #ffa33b;
    border-radius: 24px;
    padding: 6px;
    img {
      min-width: auto;
    }
    > div {
      border: none;
      width: 70%;
      > div {
        border: none;
      }
    }

    input {
      font-family: 'Archivo', sans-serif;
      font-weight: 400;
      font-size: 18px;
      letter-spacing: -0.025em;
      color: #151517;
    }

    input::placeholder {
      font-weight: 300 !important;
      font-size: 18px;
      line-height: 24px;
      color: #b0b0b0;
    }

    button {
      font-family: 'Playfair Display';
      font-size: 25px;
      width: 170px;
      color: #000;
      background: #ffa33b;
      border-radius: 20px;
      padding: 16px 10px;
      border: none;
      transition-duration: 0.3s;
      font-weight: 500;

      :hover {
        opacity: 0.8;
      }
    }
  }

  .error {
    position: absolute;
    left: 50%;
    bottom: -25px;
    transform: translateX(-50%);
  }

  .fw-800 {
    font-weight: 800;
  }

  .members {
    margin-bottom: 13rem;
    h3 {
      color: #ffa33b;
      font-family: 'Anaheim';
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 1.2px;
    }

    h2 {
      color: #000;
      font-family: 'Source Sans Pro';
      font-size: 36px;
      font-weight: 600;
      letter-spacing: 2px;
      margin: 30px 0;
    }

    p {
      color: #000;

      text-align: center;
      font-family: 'Anaheim';
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 15%;
    }
  }

  .start {
    color: #000;
    font-family: 'Anaheim';
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.6px;
    border-radius: 24px;
    border: 1px solid #ffae52;
    pointer-events: none;
    padding: 23px 38px;
    margin-bottom: 3rem;
  }

  @media screen and (min-width: 992px) {
    p {
      font-size: 22px;
    }

    form {
      button {
        font-size: 34px;
        width: 237px;
      }
    }

    .start {
      font-size: 32px;
    }
    .titles {
      img {
        width: 136px;
        height: 136px;
      }
      span {
        font-size: 28px;
      }

      h1 {
        font-size: 70px;
      }

      h2 {
        font-size: 28px;
      }
    }

    .members {
      margin-bottom: 13rem;
      h3 {
        font-size: 24px;
      }

      h2 {
        font-size: 50px;
      }

      p {
        font-size: 22px;
      }
    }

    .person1 {
      top: -9%;
      left: -30%;
    }

    .person2 {
      position: absolute;
      right: -23%;
      top: -6%;
    }

    .person3 {
      top: 49%;
      left: -35%;
    }

    .person4 {
      top: 43%;
      right: -36%;
    }

    .person5 {
      top: 103%;
      left: -28%;
    }

    .person6 {
      top: 112%;
      right: -22%;
    }

    .members {
      margin-bottom: 7%;
    }
  }
`;

export const Loading = styled.div`
  //margin-top: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 20px;
  background-color: #ffa33b;
  width: 237px !important;
  min-height: 74px;
  .spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: radial-gradient(farthest-side, #000 94%, #0000) top/9px 9px
        no-repeat,
      conic-gradient(#0000 30%, #000);
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 9px),
      #000 0
    );
    animation: spinner-c7wet2 1s infinite linear;
  }

  @keyframes spinner-c7wet2 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
