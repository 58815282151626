import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (min-width: 992px) {
    zoom: 0.7;

    .modal-dialog {
      max-width: 1350px;
    }

    .container {
      max-width: 1300px;
    }
  }

  @media screen and (min-width: 1400px) {
    zoom: 0.9;
  }

  @media screen and (min-width: 1600px) {
    zoom: 1;
  }
`;
