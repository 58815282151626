import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import logo from '~/assets/logo/logo-essentials.png';
import { Container, Loading } from './styles';
import Input from '~/components/Input';

import mail from '~/assets/icons/mail.svg';
import MemberStories from '~/components/MemberStories/indes';
import getValidationErros from '~/utils/getValidationsErrors';

interface IProps {
  className?: string;
}

interface IFormData {
  email: string;
}

const Newsletter: React.FC<IProps> = ({ className }) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = useCallback(async (data: IFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = yup.object().shape({
        email: yup
          .string()
          .email('Enter a valid email address')
          .required('Email is required'),
      });
      await schema.validate(data, { abortEarly: false });
      setLoading(true);
    } catch (error) {
      setLoading(false);
      if (error instanceof yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);
      } else {
        Swal.fire(
          'Something went wrong...',
          'Looks like what you were trying to do didn’t work, please try again.',
          'error'
        );
      }
    }
  }, []);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  console.log(email);
  return (
    <Container>
      <div className={`${className} container`}>
        <div className="row justify-content-center">
          <div className="col-lg-11 px-lg-4 text-center titles mt-n4 mt-lg-0">
            <img src={logo} alt="Logo" />
            <span className="d-block mt-n4 mt-lg-0">Wiserr Essentials</span>
            <h1 className="my-3 my-lg-4">
              Access <b className="fw-800">free, actionable insights</b> &{' '}
              <b className="fw-800">tools</b> to <b className="fw-800">grow</b>{' '}
              your business
            </h1>
            <h2>
              Unlock proven tactics, curated tools, and expert strategies
              crafted to drive real results.
            </h2>
          </div>
          <div className="col-lg-7">
            <div className="mt-4 mt-lg-5 mx-auto">
              <Form
                ref={formRef}
                id="essentials-form"
                className="position-relative d-flex align-items-center justify-content-between"
                onSubmit={handleSubmit}
              >
                <img src={mail} alt="Mail" className="pe-none ml-1 ml-sm-3" />
                <Input
                  className={`${loading ? 'pe-none' : ''} `}
                  name="email"
                  type="email"
                  placeholder="Your best email here..."
                  onChange={handleChange}
                />
                {loading ? (
                  <Loading className="m-1 ms-auto">
                    <div className="spinner" />
                  </Loading>
                ) : (
                  <button type="submit">Join Free</button>
                )}
              </Form>
              <p className="mt-4">
                Join 10,000+ entrepreneurs transforming knowledge into growth.
                Get instant access to the <b>“Actionable Growth Blueprint”</b>—
                <span>Limited Free Access!</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="members">
        <MemberStories
          textOrange="SUBSCRIBERS STORIES"
          title="Trusted by 10,000+ Entrepreneurs"
          subTitle="Learning Smarter & Growing faster"
        />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <button type="button" className="start" disabled>
              START BUILDING YOUR GROWTH JOURNEY TODAY.
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Newsletter;
