import React, { useCallback, useEffect, useState } from 'react';

import { Container, Menu } from './styles';

import star from '~/assets/icons/star-white.svg';
import ModalBuyPlan from '../ModalBuyPlan';

const HeaderLp: React.FC = () => {
  const [active, setActive] = useState(false);
  const [mode, setMode] = useState('students');
  const [showBuyPlan, setShowBuyPlan] = useState(false);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [lastScrollY, setLastScrollY] = useState<number>(0);

  const scrollToSection = useCallback((id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    setActive(false);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 40 && window.scrollY > lastScrollY && !active) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [active, lastScrollY]);

  return (
    <>
      <Container className="menu-header py-2 py-lg-3" isVisible={isVisible}>
        <div className="container-fluid container-lg container-xxl">
          <div className="row justify-content-center">
            <div className="col-12 d-lg-none">
              {/* ABAIXO MENU MOBILE */}
              <Menu active={active}>
                <div className="d-flex justify-content-center position overflow overflow-auto menu-group active">
                  <div className="d-inline-flex flex-column menu">
                    <div className="text-center mb-4">
                      <button
                        type="button"
                        className={`${
                          mode === 'students' && 'active-students-creators'
                        }`}
                        onClick={() => {
                          setMode('students');
                          scrollToSection('why');
                          setActive(false);
                        }}
                      >
                        Why Wiserr
                      </button>
                      <hr
                        className={`${
                          mode === 'students' ? 'active-hr mt-1 mb-0' : 'd-none'
                        }`}
                      />
                    </div>

                    <div className="text-center mb-4 ml-lg-5">
                      <button
                        type="button"
                        className={`${
                          mode === 'courses' && 'active-students-creators'
                        }`}
                        onClick={() => {
                          setMode('courses');
                          scrollToSection('courses');
                          setActive(false);
                        }}
                      >
                        Courses
                      </button>
                      <hr
                        className={`${
                          mode === 'courses' ? 'active-hr mt-1 mb-0' : 'd-none'
                        }`}
                      />
                    </div>

                    <div className="text-center mb-4 ml-lg-5">
                      <button
                        type="button"
                        className={`${
                          mode === 'pricing' && 'active-students-creators'
                        }`}
                        onClick={() => {
                          setMode('pricing');
                          scrollToSection('pricing');
                          setActive(false);
                        }}
                      >
                        Pricing
                      </button>
                      <hr
                        className={`${
                          mode === 'pricing' ? 'active-hr mt-1 mb-0' : 'd-none'
                        }`}
                      />
                    </div>
                  </div>
                </div>
              </Menu>
            </div>
            <div className="zoom col-11 col-lg-12 bg-white-menu rounded-pill justify-content-lg-between d-flex align-items-center text-center">
              <div className="d-none d-lg-block logo ml-lg-4">Wiserr</div>
              <Menu active={active}>
                <button
                  type="button"
                  className="d-lg-none border-0 rounded-circle mr-3"
                  onClick={() => setActive(!active)}
                >
                  <span />
                  <span />
                  <span />
                </button>
                {/* ABAIXO MENU DESKTOP */}
                <div className="d-none d-lg-flex position overflow overflow-auto menu-group active">
                  <div className="d-flex menu">
                    <div>
                      <button
                        type="button"
                        className={`${
                          mode === 'students' && 'active-students-creators'
                        }`}
                        onClick={() => {
                          setMode('students');
                          scrollToSection('why');
                        }}
                      >
                        Why Wiserr
                      </button>
                      <hr
                        className={`${
                          mode === 'students' ? 'active-hr mt-1 mb-0' : 'd-none'
                        }`}
                      />
                    </div>

                    <div className="ml-lg-5">
                      <button
                        type="button"
                        className={`${
                          mode === 'courses' && 'active-students-creators'
                        }`}
                        onClick={() => {
                          setMode('courses');
                          scrollToSection('courses');
                        }}
                      >
                        Courses
                      </button>
                      <hr
                        className={`${
                          mode === 'courses' ? 'active-hr mt-1 mb-0' : 'd-none'
                        }`}
                      />
                    </div>

                    <div className="ml-lg-5">
                      <button
                        type="button"
                        className={`${
                          mode === 'pricing' && 'active-students-creators'
                        }`}
                        onClick={() => {
                          setMode('pricing');
                          scrollToSection('pricing');
                        }}
                      >
                        Pricing
                      </button>
                      <hr
                        className={`${
                          mode === 'pricing' ? 'active-hr mt-1 mb-0' : 'd-none'
                        }`}
                      />
                    </div>
                  </div>

                  <div className="d-flex d-lg-none justify-content-center mx-auto mt-5">
                    <button
                      type="button"
                      onClick={() => {
                        setShowBuyPlan(true);
                        setActive(false);
                      }}
                      className="create justify-content-center border-none"
                    >
                      <img src={star} alt="Star" /> <span>Get Started</span>
                    </button>
                  </div>
                </div>
              </Menu>
              <div className="d-lg-none logo ml-lg-4">Wiserr</div>
              <div className="d-flex d-lg-none justify-content-center ml-auto">
                <button
                  type="button"
                  onClick={() => {
                    setShowBuyPlan(true);
                    setActive(false);
                  }}
                  className="create justify-content-center border-none"
                >
                  <img src={star} alt="Star" /> <span>Try It!</span>
                </button>
              </div>
              <div className="d-none d-lg-block">
                <button
                  type="button"
                  onClick={() => {
                    setShowBuyPlan(true);
                    setActive(false);
                  }}
                  className="create border-none"
                >
                  <img src={star} alt="Star" /> <span>Get Started</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <ModalBuyPlan
          show={showBuyPlan}
          planSelected="launchpad"
          onClose={() => setShowBuyPlan(false)}
        />
      </Container>
    </>
  );
};

export default HeaderLp;
