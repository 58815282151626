import React from 'react';

import Newsletter from '~/components/Newsletter';
import { Container } from './styles';

const Essentials: React.FC = () => {
  return (
    <Container>
      <Newsletter className="pt-5" />
    </Container>
  );
};

export default Essentials;
